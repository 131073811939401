import React from "react"
import Contacts from "../../components/contacts"
import styled from "styled-components"
import Seo from "../../components/seo"
import Quote from "../../components/about-page/quote"
import Headquarters from "../../components/about-page/headquarters"
import Interested from "../../components/about-page/interested"
import ContactUs from "../../components/about-page/contact-us"
import HeaderLp from "../../components/landing-pages/Lp3/headerLp"
import { useState, useContext } from "react"
import TryPdf from "../../components/shared/TryPdf"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import saleDate from "../../utils/saleDate"
const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
`

function About({ location }) {
  //global context
  const { setScroll, closedSticky } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }
  return (
    <>
      <Seo title="About us" noindex={true}></Seo>
      <Style>
        {closedSticky == false && (
          <StickyCtaClosable
            date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
          ></StickyCtaClosable>
        )}
        <HeaderLp
          wrapLocation={location}
          getTrial={openPopup}
          closedSticky={closedSticky}
        />
        <Quote />
        <Headquarters />
        <Interested />
        <ContactUs />
        {myPopUp === "show" && <TryPdf close={closePopup} />}
      </Style>
    </>
  )
}

export default About
